import React from 'react';
import _get from 'lodash/get';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import {Table, Col, Form, Icon, Input, AutoComplete, Row, Select, Tag, Tooltip} from 'antd';

import DealDetails from './DealDetails';
import Filters from '../../utils/Filters';
import DS from '../../utils/DefaultStyles';
import DealController from './DealController';
import {cnpjMask} from '../../utils/Formatters';
import Container from '../../components/Container';
import Headlight from '../../components/Headlight';
import CnpjAction from '../../components/CnpjAction';
import TotalTable from '../../components/TotalTable';
import CompanyInactivation from '../Company/CompanyInactivation';
import {ReactComponent as IconeNegocios} from '../../assets/images/negocios.svg';

const controller = new DealController();

const {Option} = Select;

const cnpjComponent = (deal) => <CnpjAction entity={deal} cnpj={deal.company.cnpj} Details={DealDetails}></CnpjAction>;
const headlightComponent = (text) => <Headlight text={text} />;
const actionComponent = (properties) => properties ?
  <CompanyInactivation {...properties} /> :
  <Tag color="magenta"><b>inativação solicitada</b></Tag>;

class DealListForm extends React.Component {
  constructor(props) {
    super(props);
    this.setLoading = this.setLoading.bind(this);
    this.state = {
      loading: false,
      datasource: [],
      columns: controller.columns(cnpjComponent, headlightComponent, actionComponent, this.setLoading),
      page: 1,
      pageSize: 10,
      total: 0,
    };
  }

  configInput = (component, rule) => this.props.form.getFieldDecorator(component.props.name, rule)(component);

  async componentDidMount() {
    const headlight = this.props.location.state && this.props.location.state.headlight;
    const search = this.getSearchValue();
    const {page, pageSize} = this.state;
    this.props.form.setFieldsValue({headlight});
    await controller.loadPartners(this);
    await controller.loadDeals(this, null, headlight, search, page, pageSize);
  }

  setLoading(loading) {
    this.setState({loading});
  }

  getSearchValue = () => {
    return this.props.form.getFieldValue('search') || '';
  }

  onPageChangeHandler = async (page, pageSize) => {
    this.setState({page, pageSize});
    const search = this.getSearchValue();
    const partner = this.props.form.getFieldValue('partner');
    const headlight = this.props.form.getFieldValue('headlight');
    await controller.loadDeals(this, partner, headlight, search, page, pageSize);
  }

  handleDebounced = debounce((text) => this.handleSearch(text), 800)

  handleSearch = async (search) => {
    const {page, pageSize} = this.state;
    const partner = this.props.form.getFieldValue('partner');
    const headlight = this.props.form.getFieldValue('headlight');
    await controller.loadDeals(this, partner, headlight, search, page, pageSize);
  }

  render() {
    const {columns, datasource, loading, partners, page, pageSize, total} = this.state;

    return (
      <Container loading={loading} asCard withTable>
        <Form layout="horizontal" className="yh-form">
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item label={'Filtrar Farol'}>
                {this.configInput(
                    <Select
                      id='filtroFarol'
                      name="headlight"
                      size="large"
                      onSelect={(value) => controller.filterHeadlight(this, value)}
                      style={{fontFamily: DS.font.nerisLight}}
                      placeholder="Selecione..">
                      <Option key={'all'} value={null}>Todos</Option>
                      {
                        Object.values(controller.getHeadlights()).map((headlight) =>
                          <Option key={headlight.constant} value={headlight.constant}>{headlight.text}</Option>)
                      }
                    </Select>)
                }
              </Form.Item>
            </Col>
            <Col span={12} className={!controller.isMaster() ? 'hide' : ''}>
              <Form.Item label="Filtrar Parceiro">
                {
                  this.configInput(
                      <AutoComplete
                        id='filtroParceiro'
                        filterOption={Filters.filterAutocomplete}
                        name="partner"
                        size="large"
                        onChange={(value) => controller.filterPartner(this, value)}
                        style={{fontFamily: DS.font.nerisLight}}
                        allowClear>
                        {
                          partners &&
                          partners.map((partner) =>
                            <Option key={partner.cnpj} value={partner.cnpj} style={{fontFamily: DS.font.nerisLight}}>
                              {`${cnpjMask(partner.cnpj)} - ${partner.name}`}
                            </Option>)
                        }
                      </AutoComplete>)
                }
              </Form.Item>
            </Col>
            <Col span={controller.isMaster() ? 6 : 14}>
              <Form.Item label="Pesquisar">
                <Tooltip
                  trigger={['focus']}
                  title='Pesquise por "CNPJ" ou "Nome da Loja" ou "Segmento"'
                  placement="topLeft"
                >
                  {
                    this.configInput(
                        <Input
                          id='filtroPesquisa'
                          name="search"
                          size="large"
                          onChange={(e) => this.handleDebounced(e.target.value)}
                          prefix={<Icon type="search" style={{color: 'rgba(0,0,0,.25)'}} />}>
                        </Input>)
                  }
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          dataSource={datasource}
          size="middle"
          columns={columns}
          pagination={
            {
              total,
              defaultCurrent: page,
              pageSize,
              showSizeChanger: true,
              onChange: this.onPageChangeHandler,
              onShowSizeChange: this.onPageChangeHandler,
              pageSizeOptions: ['10', '15', '20'],
            }
          }
          rowKey={(row) => _get(row, 'company.cnpj')}
          bordered={false}
          className="yh-table"/>
        <TotalTable total={total} iconElem={IconeNegocios} screen="negócios"/>
      </Container>
    );
  }
}

DealListForm.propTypes = {
  form: PropTypes.object,
  location: PropTypes.object,
};

const DealList = Form.create()(DealListForm);
export default DealList;
