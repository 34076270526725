class Rules {
  static required = (field, type='string') => {
    return {
      rules: [
        {type, required: true, message: (field ? `${field} é ` : 'Campo ') + 'obrigatório'},
      ],
    };
  }
  static email = {
    rules: [
      {type: 'string', required: true, message: 'E-mail é obrigatório'},
      {type: 'email', pattern: '/^[a-z0-9.]+@[a-z0-9]+\\.[a-z]+(\\.[a-z]+)?$/i', message: 'E-mail inválido'},
    ],
  }
  static phone = (field) => {
    return {
      rules: [
        {type: 'string', required: true, message: `${field} é obrigatório`},
        {
          validator: (rule, value, callback) => {
            if (value && value.replace( /\D+/g, '').length < 10) {
              return callback(new Error());
            }
            return callback();
          },
          message: 'Informe um telefone válido',
        },
      ],
    };
  }
}

export default Rules;
