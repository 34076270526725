import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import YhButton from '../YhButton';

class Menu extends React.Component {

  menuItem(description, model) {
    const isActive = window.location.pathname === model;
    const type = isActive ? 'primary' : 'link';
    const color = {color: type === 'link' ? 'black' : 'default'};

    return (
      <Link to={model}>
        <YhButton
          link={model}
          bold
          style={{marginRight: '10px', width: '8vw', ...color}}
          type={type}>{description}
        </YhButton>
      </Link>
    );
  }

  render() {
    const {router} = this.props;
    return (
      <nav style={{paddingTop: '30px', paddingBottom: '30px'}}>
        {this.menuItem('Início', router.inicio)}
        {this.menuItem('Negócios', router.negocios)}
      </nav>
    );
  }
}

Menu.propTypes = {
  router: PropTypes.object,
};

export default Menu;
