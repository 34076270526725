/* eslint-disable camelcase */
class Contact {
  constructor({name, email, phone, cargo}) {
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.cargo = cargo;
  }
}

export default Contact;
