import React from 'react';
import {Tag} from 'antd';
import PropTypes from 'prop-types';

import {Headlights} from '../../mocks';

const Headlight = ({text}) => {
  const headlight = Headlights[text];
  if (!headlight) {
    return '--';
  }
  return (
    <Tag color={headlight.color}><b>{headlight.text}</b></Tag>
  );
};

Headlight.propTypes = {
  text: PropTypes.string,
};

export default Headlight;
