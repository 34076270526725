import React from 'react';
import {Typography} from 'antd';
import PropTypes from 'prop-types';

import DS from '../../utils/DefaultStyles';

const {Paragraph} = Typography;

const clearNotAdherentProps = (props) => {
  delete props.fallback;
  delete props.copyable;
};

const Text = (props) => {
  const properties = {...{fallback: true}, ...props};
  const face = props.text && !isNaN(props.text) ? DS.font.nerisLight : DS.font.default;
  const fallback = !properties.fallback ? '' : '--';

  clearNotAdherentProps(properties);

  return (
    <font {...properties} face={face}>
      {
        props.copyable ?
        <Paragraph copyable style={{marginBottom: 0}}>{props.children || fallback}</Paragraph> :
        props.children || fallback
      }
    </font>
  );
};

Text.propTypes = {
  text: PropTypes.string,
  children: PropTypes.string,
  fallback: PropTypes.bool,
  copyable: PropTypes.bool,
};

export default Text;
