import React from 'react';
import PropTypes from 'prop-types';
import {Empty, Skeleton} from 'antd';
import ReactApexChart from 'react-apexcharts';

import BarHeadlightsController from './BarHeadlightsController';

const controller = new BarHeadlightsController();

const BarHeadlights = ({loading, data, handleRedirect}) => {
  return (
    <Skeleton loading={loading} active>
      {data ? <ReactApexChart {...controller.construct(data, handleRedirect)} type="bar" height={350} /> : <Empty />}
    </Skeleton>
  );
};

BarHeadlights.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  handleRedirect: PropTypes.func,
};

export default BarHeadlights;
