import React from 'react';
import PropTypes from 'prop-types';

function Background({children}) {
  return (
    <div style={{background: '#F2F2F2', padding: '0 10vw 10vh 10vw', minHeight: '91vh'}}>
      {children}
    </div>
  );
}

Background.propTypes = {
  children: PropTypes.any,
};

export default Background;
