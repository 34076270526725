const safe = (content) => {
  try {
    return content || '';
  } catch {
    return '--';
  }
};

const onlyNumbers = (text, isPhone=false) => safe(text.replace(isPhone ? /(?!\+)/g : /\D+/g, ''));
const cnpjMask = (text) => safe(text.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'));
const cepMask = (text) => safe(onlyNumbers(text).replace(/^(\d{5})(\d{3})/, '$1-$2'));
const phoneMask = (text) => {
  const tel = safe(text).replace(/^(\d{2})(\d)/g, '($1) $2');
  const conditionalMask = tel.length > 11 && tel[tel.length-9] === '9' ? /(\d)(\d{2})(\d{5})(\d{4})$/ : /(\d)(\d{2})(\d{4})(\d{4})$/;
  return tel.replace(conditionalMask, '$1 $2 $3 $4');
};

const currencyToFloat = (text) => {
  const foo = text.replace(/[a-z]\W/gi, '');
  const bar = foo.replace('.', '');
  return parseFloat(bar.replace(',', '.'));
};

export {onlyNumbers, cnpjMask, currencyToFloat, cepMask, phoneMask};
