import React from 'react';
import {Button, Modal} from 'antd';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import swagger_json from '../../assets/doc-api-yi-swagger.json';

class DocYI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  openCloseModal = () => {
    const {modalVisible} = this.state;
    this.setState({modalVisible: !modalVisible});
  }

  userModalContent = () => (
    <SwaggerUI spec={swagger_json} />
  )

  docYandehIntegration = () => (
    <Modal title="Documentação Yandeh Integration"
      visible={this.state.modalVisible}
      onCancel={this.openCloseModal}
      width={1600}
      footer={null}
      maskClosable={false}
    >
      {this.userModalContent()}
    </Modal>
  )

  render() {
    return <>
      <Button className="doc-yi bt-header" type="primary" shape="circle" icon="api" onClick={this.openCloseModal} />
      {this.docYandehIntegration()}
    </>;
  }
}

export default DocYI;
