import React from 'react';
import {Button, Dropdown, Menu, message, Upload, Spin} from 'antd';

import Api from '../../services/Api';
import Context from '../../utils/Context';
import {guideService} from '../../services';

class Guide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      headers: null,
    };
  }

  onChange = (info) => {
    let loading = false;
    const {status} = info.file;

    if (status === 'uploading') {
      loading = true;
    }

    if (status === 'done') {
      loading = false;
      message.success(`${info.file.name} arquivo inserido com sucesso.`);
    } else if (status === 'error') {
      loading = false;
      message.error(`${info.file.name} houve uma falha ao inserir o arquivo.`);
    }
    this.setState({loading});
  }

  mountUploadHeaders = () => {
    Api.requestConfig({headers: {authorization: ''}}).then((config) => {
      this.setState({headers: config.headers});
    });
  }

  menu = () => {
    const {headers} = this.state;
    return (
      <Menu style={{textAlignLast: 'center'}}>
        <Menu.Item key="1">
          <a target="_blank" rel="noopener noreferrer" href={guideService.guideUrl()}>
            Manual
          </a>
        </Menu.Item>
        {
          Context.isMaster() &&
          <Menu.Item key="2">
            <Upload
              name="data"
              action={guideService.uploadServiceUrl()}
              accept=".pdf"
              onChange={this.onChange}
              headers={headers}
              beforeUpload={this.beforeUpload}>
              <Button icon="upload">Inserir</Button>
            </Upload>
          </Menu.Item>
        }
      </Menu>
    );
  }

  render() {
    return (
      <>
        <Dropdown overlay={this.menu} trigger={['click']}>
          <Button className="question bt-header" type="primary" shape="circle" icon="question" onClick={this.mountUploadHeaders}/>
        </Dropdown>
        <Spin className="spinIsolate" spinning={this.state.loading}/>
      </>
    );
  }
}

export default Guide;
