import {companyService, contactService} from '../services';

export default {
  async _genericLoad(instance, get, entity, params) {
    instance.setState({loading: true});
    await get(params)
        .then((res) => instance.setState({[entity]: res}))
        .finally(() => instance.setState({loading: false}));
  },
  async loadSegments(instance) {
    await this._genericLoad(instance, this.getSegments, 'segmentos');
  },
  async loadCompanies(instance, parternCnpj) {
    instance.setState({loading: true});
    await this._genericLoad(instance, this.getCompanies, 'companies', {parternCnpj});
  },
  async loadResponsibilities(instance) {
    await this._genericLoad(instance, this._getResponsabilities, 'responsabilities');
  },
  async getSegments() {
    return companyService.getSegmentos().then((res) => res.data.segmentos);
  },
  async getCompanies({companyCnpj, parternCnpj}) {
    return companyService.getEmpresas(companyCnpj, parternCnpj).then((res) => res.data.companies);
  },
  async _getResponsabilities() {
    return contactService.getCargos().then((res) => res.data.cargos);
  },
  disableForm(fields, ...exceptions) {
    if (!fields) {
      return true;
    }
    exceptions.map((exception) => delete fields[exception]);
    return Object.values(fields).some((val) => typeof val === 'object' ? this.disableForm(val) : !val && val !== 0);
  },
  validateAccess(instance, fallback) {
    if (window.localStorage.getItem('perfil') === 'MASTER') {
      this.go(instance, fallback);
    }
  },
  go(instance, fallback) {
    return instance.props.history.push(fallback);
  },
};

