import React from 'react';
import {Button, Modal} from 'antd';

class DocColetor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  openCloseModal = () => {
    const {modalVisible} = this.state;
    this.setState({modalVisible: !modalVisible});
  }

  userModalContent() {
    return (
      <center>
        <iframe
          title='doc-coletor-yandeh'
          src={process.env.PUBLIC_URL + '/book/index.html'}
          width={1500}
          height={700}
          frameBorder={0}>
        </iframe>
      </center>
    );
  }

  docColetorYandeh = () => (
    <Modal title="Documentação Coletor Yandeh"
      visible={this.state.modalVisible}
      onCancel={this.openCloseModal}
      width={1600}
      footer={null}
      maskClosable={false}
    >
      {this.userModalContent()}
    </Modal>
  )

  render() {
    return <>
      <Button className="doc-cy bt-header" type="primary" shape="circle" icon="database" onClick={this.openCloseModal} />
      {this.docColetorYandeh()}
    </>;
  }
}

export default DocColetor;
