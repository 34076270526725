import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Button, Dropdown} from 'antd';

const Profile = () => {
  const {user} = useAuth0();


  const profileMenu = () => {
    return (
      <div className="profile-menu">
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
      </div >
    );
  };
  return (<Dropdown
    overlay={profileMenu()} trigger={['click']}>
    <Button
      type="primary"
      shape="circle"
      icon="profile"
      className='bt-header'
    >
    </Button>
  </Dropdown>)

  ;
};

export default Profile;
