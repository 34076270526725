import _get from 'lodash/get';
import _debounce from 'lodash.debounce';

import Rules from '../../utils/Rules';
import Deal from '../../entities/Deal';
import Context from '../../utils/Context';
import generic from '../GenericController';
import {Headlights} from '../../mocks';
import {Contact, Partner} from '../../entities';
import {dealService, companyService, contactService, erpLinkService} from '../../services';
import {MessageDealer, messages} from '../../components/MessageDealer';
import {cnpjMask} from '../../utils/Formatters';
import {BITRIX_COMPANY_TYPES} from '../../utils/Contants';

class DealController {
  columns(cnpjComponent, headlightComponent, actionComponent, setLoading) {
    let columns = [
      {
        title: 'CNPJ',
        key: 'company.cnpj',
        dataIndex: 'company.cnpj',
        sorter: (a, b) => _get(a, 'company.cnpj') - _get(b, 'company.cnpj'),
        render: (text, record) => cnpjComponent(Deal.fromApi(record)),
      },
      {
        title: 'Loja',
        key: 'company.name',
        dataIndex: 'company.name',
        sorter: (a, b) => a.company.name.localeCompare(b.company.name),
      },
      {
        title: 'Segmento',
        key: 'business_unit',
        dataIndex: 'business_unit',
        sorter: (a, b) => a.business_unit.localeCompare(b.business_unit),
      },
      {
        title: 'Farol',
        key: 'headlight',
        dataIndex: 'headlight',
        render: (text) => headlightComponent(text && text.toUpperCase()),
        sorter: (a, b) => a && a.headlight.localeCompare(b.fase),
      },
    ];
    const masterColumns = [
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status),
      },
    ];
    if (Context.isMaster()) {
      columns = columns.filter((columns) => columns.title !== 'Segmento');
      columns.unshift({
        title: 'Parceiro',
        key: 'company.erp.name',
        dataIndex: 'company.erp.name',
        sorter: (a, b) => a.company.erp.name.localeCompare(b.company.erp.name),
      });
      columns = columns.concat(masterColumns);
    } else {
      columns.push({
        title: 'Ações',
        align: 'center',
        render: (record) => {
          return actionComponent(!record.company.inactivation && actionModalProps(record.company.cnpj, setLoading));
        },
      });
    }
    return columns;
  }

  async initializeForm(instance) {
    generic.validateAccess(instance, '/negocios');
    await generic.loadSegments(instance);
    await generic.loadResponsibilities(instance);
    this._fillPartner(instance, null);
    const cnpj = instance.props.match.params.cnpj;
    if (cnpj) {
      _self.loadFormWithExistentData(instance, cnpj);
      instance.setState({isEdition: true});
    }
  }

  loadFormWithExistentData(instance, cnpj) {
    instance.setState({loading: true});
    _self._getDeal(cnpj)
        .then((negocio) => Deal.fromApi(negocio))
        .then((deal) =>_self._fillForm(instance, deal))
        .finally(() => instance.setState({loading: false}));
  }

  async _getDeal(companyCnpj, parternCnpj) {
    const {negociacoes} = await _self._getDeals({companyCnpj, parternCnpj});
    return negociacoes.length && negociacoes[0];
  }

  _fillForm(instance, deal) {
    this._fillDeal(instance, deal);
    this._fillCompany(instance, deal.company);
    this._fillContact(instance, deal.contact);
  }

  _fillDeal(instance, deal) {
    const {company, businessUnit, amountCharged, amountTransfer, origin} = deal;
    const {cnpj} = company;

    instance.setState({origin});

    instance.props.form.setFieldsValue({company: cnpj, businessUnit,
      amountCharged: amountCharged.toString().replace('.', ',') || '0',
      amountTransfer: amountTransfer.toString().replace('.', ',') || '0'});
  }

  _fillCompany(instance, company) {
    const {cnpj, phone, pdv, profile, email, billingAmount, type} = company;
    instance.props.form.setFieldsValue({cnpj, phone, pdv, email,
      billingAmount: (billingAmount || '0').toString().replace('.', ',')});
    if (profile) {
      _self._loadProfiles(instance);
      instance.props.form.setFieldsValue({profile});
    }
    if (type && BITRIX_COMPANY_TYPES.filter((el) => el.key === type)) {
      instance.props.form.setFieldsValue({'company.type': type});
    }
    this._fillPartner(instance, company.partner);
  }

  _fillContact(instance, contact) {
    instance.props.form.setFieldsValue({contact});
  }

  _fillPartner(instance, partner) {
    console.log(partner);
    const partnerCnpj = partner ? partner.cnpj : Context.idPartner();
    partnerCnpj && instance.props.form.setFieldsValue({partnerCnpj});
  }

  async loadDeals(instance, partnerCnpj, headlight, search, page, pageSize) {
    instance.setState({loading: true});
    await this._getDeals({parternCnpj: partnerCnpj || Context.idPartner(), headlight, search, page, pageSize})
        .then(({negociacoes, total}) => {
          instance.setState({datasource: negociacoes, originalData: negociacoes, total});
        }).finally(() => instance.setState({loading: false}));
  }

  async loadPartners(instance) {
    if (Context.isMaster()) {
      await erpLinkService.getAllPartners()
          .then((res) => res.data.partners)
          .then((partners) => {
            const partnerList = partners.map((partner) => new Partner(partner));
            instance.setState({partners: partnerList});
          })
          .catch((err) => {
            console.log(err);
          });
      return;
    }
    instance.setState({partners: []});
  }

  getHeadlights() {
    return Object.values(Headlights);
  }

  handleSegment() {
    this.props.form.resetFields('profile');
    _self._loadProfiles(this);
  }

  async _loadProfiles(instance) {
    const segmento = instance.props.form.getFieldValue('businessUnit');
    instance.setState({perfis: []});
    await _self._getProfiles(segmento).then((perfis) => instance.setState({perfis}));
  }

  async _getProfiles(segmento) {
    return companyService.getPerfis(segmento).then((res) => res.data.perfis);
  }

  handleEmailBlur(event) {
    event.preventDefault();
    if (!_self._validEmail(this)) {
      _self._ClearEmailFeedback(this);
      return;
    }
    _self._ConsultingEmail(this);
    _self.loadContactWithDataBase(this);
  }

  loadContactWithDataBase(instance) {
    const email = instance.props.form.getFieldValue('contact.email');
    contactService.get(email)
        .then((res) => res.data.contact)
        .then((contactData) => new Contact(contactData))
        .then((contact) => {
          this._emailConsulted(instance);
          this._fillContact(instance, contact);
        }).catch(() => this._emailNotFound(instance, 'CNPJ inválido ou inexistente.'));
  }

  _ConsultingEmail(instance) {
    instance.setState({
      'statusContato': {validateStatus: 'validating', help: 'Verificando contato...', hasFeedback: true},
    });
  }

  _emailConsulted(instance) {
    instance.setState({
      'statusContato': {validateStatus: 'success', help: 'Contato carregado com sucesso', hasFeedback: true},
    });
  }

  _emailNotFound(instance) {
    instance.setState({
      'statusContato': {validateStatus: 'warning', help: 'Contato novo', hasFeedback: true},
    });
  }

  _ClearEmailFeedback(instance) {
    instance.setState({
      'statusContato': {},
    });
  }

  filterHeadlight(instance, val) {
    const partner = instance.props.form.getFieldValue('partner');
    const search = instance.getSearchValue();
    const {page, pageSize} = instance.state;
    this.loadDeals(instance, partner, val, search, page, pageSize);
  }

  filterPartner(instance, value) {
    const debounced = _debounce((val) => {
      if (!val || !isNaN(val)) {
        const headlight = instance.props.form.getFieldValue('headlight');
        const search = instance.getSearchValue();
        const {page, pageSize} = instance.state;
        this.loadDeals(instance, val, headlight, search, page, pageSize);
      }
    }, 300);
    debounced(value);
  }

  _getDeals({companyCnpj, parternCnpj, headlight, search, page, pageSize}) {
    return dealService.getNegocios(companyCnpj, parternCnpj, headlight, encodeURI(search), page, pageSize)
        .then((res) => res.data);
  }

  disableContactFields(instance) {
    return !this._validEmail(instance);
  }

  disableCompanyFields(instance) {
    return instance.props.form.getFieldError('cnpj') || !instance.props.form.getFieldValue('cnpj');
  }

  _validEmail(instance) {
    return !instance.props.form.getFieldError('contact.email') && instance.props.form.getFieldValue('contact.email');
  }

  add() {
    this.props.history.push('/negocio2');
  }

  companyOptionDescription(company) {
    return `${cnpjMask(company.cnpj)} - ${company.name}`;
  }

  async inactivateCompany(values, transients) {
    const {content} = values;
    const {cnpj, setLoading} = transients;

    const cb = () => window.location.reload();

    setLoading(true);
    await companyService.inactivate(cnpj, content)
        .then(() => {
          return MessageDealer.success(messages.success.INATIVACAO_SOLICITADA, cb);
        })
        .catch((err) => {
          return MessageDealer.dynamicResponseError(err, messages.error.FALHA_INATIVACAO);
        })
        .finally(() => setLoading(false));
  }

  isMaster = () => Context.isMaster()
}

const _self = new DealController();

const actionModalProps = (cnpj, setLoading) => {
  return {
    viewer: true,
    icon: 'stop',
    tooltip: 'Solicitar Inativação',
    style: {color: 'black'},
    rules: Rules.required('Motivo da Inativação'),
    callback: _self.inactivateCompany,
    transients: {cnpj, setLoading},
    width: 660,
  };
};

export default DealController;
