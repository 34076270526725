/* eslint-disable camelcase */
import api from './Api';

const BASE_ENDPOINT = '/portal-erp/deal';

export default {
  save(payload) {
    return api.post(`${BASE_ENDPOINT}`, payload);
  },
  update(payload) {
    return api.put(`${BASE_ENDPOINT}`, payload);
  },
  getNegocios(cnpj, cnpj_erp, headlight, search, page, size) {
    return api.get(`${BASE_ENDPOINT}`, {cnpj, cnpj_erp, headlight, search, page, size});
  },
};
