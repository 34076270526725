import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Tooltip, Form, Radio, Row} from 'antd';

import Container from '../Container';
import YhButton from '../YhButton';
import DS from '../../utils/DefaultStyles';
import TitleFormItem from '../TitleFormItem';

class ActionModalView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
    };
    this.onOk = this.onOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  onOk(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loading: true});
        const {transients} = this.props;
        this.props.callback(values, transients).then(() => {
          this.handleCancel();
        });
      }
    });
  }

  handleCancel() {
    this.setState({loading: false, visible: false});
    this.props.form.resetFields();
  }

  options() {
    return (
      <Radio.Group buttonStyle="solid">
        <Radio.Button value="Loja deixou de ser cliente">Loja deixou de ser cliente</Radio.Button>
        <Radio.Button value="Loja inadimplente">Loja inadimplente</Radio.Button>
        <Radio.Button value="Loja não é cliente">Loja não é cliente</Radio.Button>
        <Radio.Button value="Outros">Outros</Radio.Button>
      </Radio.Group>
    );
  }

  footer() {
    const style = {fontFamily: DS.font.ralewaySemiBold};
    return (
      <Row type="flex" justify="center">
        <YhButton style={style}>Cancelar</YhButton>
        <YhButton style={style} type="primary">Solicitar</YhButton>
      </Row>
    );
  }

  render() {
    const {visible, loading} = this.state;
    const {
      form,
      icon,
      style = {},
      title,
      actionTitle,
      footer = null,
      width = 520,
      rules = {},
      tooltip = 'Acionar',
      type = 'link',
      shape = 'circle',
      size = 'large',
    } = this.props;

    const {getFieldDecorator} = form;
    return (
      <>
        <Tooltip title={tooltip}>
          <Button
            type={type}
            shape={shape}
            icon={icon}
            size={size}
            style={style}
            onClick={() => this.setState({visible: true})}/>
        </Tooltip>
        <Modal
          title={title}
          visible={visible}
          width={width}
          footer={footer(this.handleCancel, this.onOk)}
          onOk={this.onOk}
          onCancel={this.handleCancel}>
          <Container loading={loading} asCard={false}>
            <Row type="flex" justify="center">
              <Form layout="vertical" onSubmit={this.onOk} style={{alignSelf: 'center'}}>
                <Form.Item label={<TitleFormItem>{actionTitle}</TitleFormItem>}>
                  {getFieldDecorator('content', rules)(this.options())}
                </Form.Item>
              </Form>
            </Row>
          </Container>
        </Modal>
      </>
    );
  }
}

ActionModalView.propTypes = {
  tooltipTitle: PropTypes.string,
  style: PropTypes.object,
  viewer: PropTypes.bool,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
  title: PropTypes.element,
  actionTitle: PropTypes.string,
  type: PropTypes.string,
  shape: PropTypes.string,
  size: PropTypes.string,
  callback: PropTypes.func,
  rules: PropTypes.object,
  form: PropTypes.object,
  transients: PropTypes.object,
  width: PropTypes.number,
  footer: PropTypes.func,
};

const ActionModal = Form.create()(ActionModalView);
export default ActionModal;
