/* eslint-disable camelcase */
import axios from 'axios';
import FormData from 'form-data';

const BASE_URL = process.env.REACT_APP_API_URL_BARRAMENTO || 'http://localhost:8080';

class Api {
  constructor() {
    this.axios = axios.create({
      baseURL: BASE_URL,
      timeout: 300000,
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'x-api-origin': 'AUTH0',
      },
    });
    this.configureInterceptors();
  }

  async _getTokenExchange() {
    const {access_token} = await window.YandehHub.Auth.exchangeToken('barramento-controller').then((res) => res.json());
    return access_token;
  }

  get(path, params) {
    return this.axios.get(path, {params});
  }

  download(path, params) {
    return this.axios({
      url: path,
      method: 'get',
      withCredentials: false,
      responseType: 'blob',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      params: params,
    });
  }

  post(path, params) {
    return this.axios.post(path, params);
  }

  put(path, params) {
    return this.axios.put(path, params);
  }

  delete(path, params) {
    return this.axios.delete(path, {data: params});
  }

  postFile(path, key, file) {
    const formData = new FormData();
    formData.append(key, file);
    return this.axios.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async requestConfig(config) {
    const access_token = window.localStorage.token;
    // console.log('ESTÁ EXPIRANDO?', window.YandehHub.Auth.isTokenExpired(120));
    config.headers.authorization = `Bearer ${access_token}`;
    return Promise.resolve(config);
  }

  responseErrorHandler = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        window.localStorage.clear();
      }
      return Promise.reject(error);
    }
    throw error;
  }

  configureInterceptors = () => {
    this.axios.interceptors.request.use(this.requestConfig);
    this.axios.interceptors.response.use((res) => res, this.responseErrorHandler);
  }
}

export default new Api();
