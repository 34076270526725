/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import {ConfigProvider, Spin} from 'antd';
import pt_BR from 'antd/es/locale-provider/pt_BR';

import App from '../../App';
import AuthService from '../../services/AuthService';
import {useAuth0} from '@auth0/auth0-react';

function ApplicationWrapper() {
  const [initialized, setInitialized] = useState(false);
  const [loadingMessage] = useState('Aguarde...');
  const [loading, setLoading] = useState(true);
  const {getIdTokenClaims, logout} = useAuth0();


  const _clearStorage = () => {
    window.localStorage.removeItem('perfil');
    window.localStorage.removeItem('logo');
    window.localStorage.removeItem('id_parceiro');
  };

  const loadProfile = async () => {
    _clearStorage();
    const profile = await AuthService.auth().then((res) => res.data);
    const {perfil, logo, id_parceiro} = profile;

    window.localStorage.setItem('perfil', perfil);
    logo && window.localStorage.setItem('logo', logo);
    id_parceiro && window.localStorage.setItem('id_parceiro', id_parceiro);
  };

  const getToken = async () => {
    try {
      const token = await getIdTokenClaims();
      window.localStorage.setItem('token', token.__raw);
      setInitialized(true);
      setLoading(true);
    } catch (error) {
      window.localStorage.removeItem('token');
      logout({returnTo: window.location.origin});
    }
  };

  const makePostAuthentication = async () => {
    console.log('makePostAuthentication');
    setLoading(true);
    await getToken();
    await loadProfile();
  };

  useEffect(() => {
    makePostAuthentication();
  }, [getIdTokenClaims]);

  if (initialized) {
    return (
      <>
        <ConfigProvider locale={pt_BR}><App /></ConfigProvider>
      </>
    );
  }
  return <div className="spinCenter">
    <Spin spinning={loading} tip={loadingMessage} />
  </div>;
}

export default ApplicationWrapper;
