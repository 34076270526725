import React from 'react';
import PropTypes from 'prop-types';

import DS from '../../utils/DefaultStyles';

const TotalTable = (props) => {
  const IconElem = props.iconElem;
  return (
    <section style={{position: 'absolute', bottom: '36px', left: '36px', fontFamily: DS.font.nerisSemiBold}}>
      <IconElem width={30} style={{verticalAlign: 'middle'}}/>
      <span style={{verticalAlign: 'middle', marginLeft: '10px'}}> {props.total} {props.screen}</span>
    </section>
  );
};

TotalTable.propTypes = {
  total: PropTypes.number,
  iconElem: PropTypes.object,
  screen: PropTypes.string,
};

export default TotalTable;
