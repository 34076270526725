import api from './Api';

const server = process.env.REACT_APP_API_URL_BARRAMENTO;

export default {
  getERPLinks() {
    return api.get(`${server}/portal-erp/links`);
  },
  getAllPartners() {
    return api.get(`${server}/portal-erp/partners`);
  },
};
