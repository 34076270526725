import React from 'react';
import {Button} from 'antd';
import _merge from 'lodash/merge';
import DS from '../../utils/DefaultStyles';
import PropTypes from 'prop-types';

const clearNotAdherentProps = (props) => {
  delete props.bold;
};

export default function YhButton(props) {
  const font = props.bold ? DS.font.ralewayBold : DS.font.ralewaySemiBold;
  const properties = _merge({style: {fontFamily: font, minWidth: '117px'}}, props);

  clearNotAdherentProps(properties);

  return (<Button {...properties} size="large"></Button>);
}

YhButton.propTypes = {
  bold: PropTypes.bool,
};
