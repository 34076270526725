import {Contact, Company} from '.';

class Deal {
  constructor({
    fase,
    businessUnit,
    status,
    origin,
    headlight,
    amountCharged,
    amountTransfer,
    termLink,
    contact,
    company,
  }) {
    this.fase = fase;
    this.businessUnit = businessUnit;
    this.status = status;
    this.origin = origin;
    this.headlight = headlight;
    this.amountCharged = amountCharged;
    this.amountTransfer = amountTransfer;
    this.termLink = termLink;
    this.contact = contact;
    this.company = company;
  }

  static fromApi(data) {
    return new Deal({
      fase: data.fase,
      businessUnit: data.business_unit,
      status: data.status,
      origin: data.origin,
      headlight: data.headlight,
      amountCharged: data.amount_charged,
      amountTransfer: data.amount_transfer,
      termLink: data.term_link,
      contact: new Contact(data.contact),
      company: Company.fromApi(data.company),
    });
  }
}

export default Deal;
