const CONTACTE_ADMIN = 'Entre em contato com o administrador do sistema.';
const messages = {
  error: {
    CNPJ_NAO_ENCONTRADO: 'CNPJ não encontrado',
    FALHA_CONEXAO: 'Falha de Conexão, tente novamente mais tarde',
    VALIDACAO_FORMULARIO: 'Erro de validação, verifique o formulário',
    NEGOCIO_NAO_ENCONTRADO: 'Negócio não encontrado',
    IMAGEM_NAO_ALTERADA: 'Não foi possível alterar a imagem',
    ERRO_GENERICO_CADASTRO: 'Não foi possível realizar o cadastro. Tente novamente mais tarde.',
    PROBLEMA_COM_DADOS_ENVIADOS: 'Os dados informados não são válidos, verifique se existem inconsistências no cadastro.',
    FALHA_INATIVACAO: `Houve uma falha ao tentar inativar esta Empresa. ${CONTACTE_ADMIN}`,
  },
  success: {
    cadastroEmpresa: (isEdition) => `Empresa ${isEdition ? 'alterada' : 'cadastrada'} com sucesso`,
    cadastroNegócio: (isEdition) => `Negócio ${isEdition ? 'alterado' : 'cadastrado'} com sucesso`,
    LOJA_CADASTRADA_SUCESSO: 'Loja cadastrada com sucesso',
    CADASTRO_REALIZADO: 'Cadastro realizado com sucesso',
    NEGOCIO_CADASTRADO: 'Negócio cadastrado com sucesso',
    NEGOCIO_EXCLUIDO: (id) => `Negócio ${id} excluído com sucesso`,
    IMAGEM_ALTERADA: 'Imagem alterada com sucesso',
    USUARIO_CADASTRADO: 'Usuário cadastrado com sucesso',
    INATIVACAO_SOLICITADA: 'Inativação solicitada com sucesso. A página será recarregada.',
  },
  info: {
    INSTRUCOES_RECUPERACAO_SENHA: 'Você receberá dentro de instantes um e-mail com instruções para recuperar sua senha',
  },
  tooltip: {
    USER_EMAIL: 'E-mail para o qual será direcionado as instruções de boas vindas e o login para acessar o Portal Inteligente',
  },
};

export default messages;
