import React from 'react';
import {Layout} from 'antd';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';

import Menu from './components/Menu';
import RouteOptions from './RouteOptions';
import {Dashboard} from './pages/Dashboard';
import {DealList} from './pages/Deal';
import Background from './components/Background';
import {default as Header} from './components/Header';
// import {CompanyForm, CompanyList} from './pages/Company';

import './App.less';

const NoMatch = (props) => (
  <Redirect to={RouteOptions.inicio} />
);

const App = () => {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Header />
          <Background>
            <Menu router={RouteOptions}/>
            <Switch>
              <Route exact path={RouteOptions.inicio} component={Dashboard} />
              <Route exact path={RouteOptions.negocios} component={DealList}/>
              <Route component={NoMatch}/>
            </Switch>
          </Background>
        </Layout>
      </Router>
    </div>
  );
};

export default App;
