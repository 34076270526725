import {onlyNumbers} from '../utils/Formatters';

class Address {
  constructor({neighborhood, cep, city, complement, uf, street, number}) {
    this.neighborhood = neighborhood;
    this.cep = cep;
    this.city = city;
    this.complement = complement;
    this.uf = uf;
    this.street = street;
    this.number = number;
  }

  static fromSerpro(data) {
    return new Address({
      cep: onlyNumbers(data.cep),
      city: data.municipio,
      complement: data.complemento,
      neighborhood: data.bairro,
      number: data.numero,
      uf: data.uf,
      street: data.logradouro,
    });
  }

  static fromApi(data) {
    return new Address({
      cep: onlyNumbers(data.address_cep),
      city: data.address_city,
      complement: data.address_complement,
      neighborhood: data.address_neighborhood,
      number: data.address_number,
      uf: data.address_uf,
      street: data.address,
    });
  }
}

export default Address;
