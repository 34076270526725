/* eslint-disable camelcase */
import React from 'react';
import ReactDOM from 'react-dom';

import {Auth0Provider} from '@auth0/auth0-react';

import Authenticator from './components/Authenticator';


const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const client_id = process.env.REACT_APP_AUTH0_PORTAL_ERP_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
ReactDOM.render(
    <Auth0Provider
      domain={domain}
      clientId={client_id}
      audience={audience}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: 'openid profile email offline_access',
      }}
    >
      <Authenticator />
    </Auth0Provider>, document.getElementById('root'));


