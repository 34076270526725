import React from 'react';
import {Typography, Row} from 'antd';

import DS from '../../utils/DefaultStyles';

const {Title} = Typography;

function TitleModal(props) {
  return (
    <Row type="flex" justify="center">
      <Title
        style={{color: '#707478', fontFamily: DS.font.ralewaySemiBold, marginBottom: 0}}
        level={4} {...props}></Title>
    </Row>
  );
}

export default TitleModal;
