import './styles.css';
import React from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row} from 'antd';

import RouteOptions from '../../RouteOptions';
import BarHeadlights from './BarHeadlights';
import TitleColumn from '../../components/TitleColumn';
import DashboardController from './DashboardController';

const controller = new DashboardController();

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loadingBarHeadlights: false};
  }

  componentDidMount() {
    controller.loadDashboards(this);
  }

  redirectFiltering = (headlight) => {
    const {history} = this.props;
    history.push({
      pathname: RouteOptions.negocios,
      state: {headlight},
    });
  }

  render() {
    const {
      loadingBarHeadlights,
      dataBarHeadlights,
    } = this.state;

    return (
      <>
        <Row gutter={24}>
          <Col span={16}>
            <Card>
              <TitleColumn>Status dos negócios</TitleColumn>
              <BarHeadlights loading={loadingBarHeadlights} data={dataBarHeadlights} handleRedirect={this.redirectFiltering} />
            </Card>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12}></Col>
        </Row>
      </>
    );
  }
}

Dashboard.propTypes = {
  props: PropTypes.object,
  history: PropTypes.object,
};

export default Dashboard;
