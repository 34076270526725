import React from 'react';
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react';
import ApplicationWrapper from '../ApplicationWrapper';


const Auth0 = () => {
  const {isAuthenticated} = useAuth0();

  return <ApplicationWrapper isAuthenticated={isAuthenticated} />;
};

export default withAuthenticationRequired(Auth0, {
  onRedirecting: () => <div>Carregando aplicação...</div>,
});
