import React from 'react';
import PropTypes from 'prop-types';
import {Button, Tooltip} from 'antd';

import Text from '../Text';
import {cnpjMask} from '../../utils/Formatters';

class CnpjAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {visible: false};
  }

  handleDetails(visible) {
    this.setState({visible});
  }

  render() {
    const {entity, cnpj, Details} = this.props;
    return (
      <div id="paiDeTodos">
        <Tooltip title="Visualizar Detalhes" getPopupContainer={() => document.getElementById('paiDeTodos')}>
          <Button
            type="link"
            style={{color: '#4064B4', textDecoration: 'underline', padding: 0}}
            onClick={() => this.handleDetails(true)}>
            <Text text={cnpj}>{cnpjMask(cnpj)}</Text>
          </Button>
          <Details
            visible={this.state.visible}
            close={() => this.handleDetails(false)}
            entity={entity}/>
        </Tooltip>
      </div>
    );
  }
}

CnpjAction.propTypes = {
  entity: PropTypes.any,
  cnpj: PropTypes.string,
  Details: PropTypes.func,
};

export default CnpjAction;

