import {Row} from 'antd';
import React from 'react';

import DS from '../../utils/DefaultStyles';
import YhButton from '../../components/YhButton';
import {ActionModal} from '../../components/ActionModal';
import TitleModal from '../../components/TitleModal/TitleModal';

const Title = () => {
  return (<TitleModal>Solicitação de inativação de loja</TitleModal>);
};

const footer = (onCancel, onOk) => {
  const style = {width: '117px', fontFamily: DS.font.ralewaySemiBold};
  return (
    <Row type="flex" justify="center">
      <YhButton
        style={style}
        onClick={onCancel}>Cancelar</YhButton>
      <YhButton
        style={style}
        onClick={onOk}
        type="primary">Solicitar</YhButton>
    </Row>
  );
};

const CompanyInactivation = (props) => {
  return (
    <ActionModal key="action-modal" actionTitle="Motivo" title={<Title/>} footer={footer} {...props}/>
  );
};

export default CompanyInactivation;
