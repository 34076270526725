import {Modal, notification} from 'antd';

const body = (title, message, callback) => ({
  message: title,
  description: message,
  placement: 'bottomRight',
  duration: 3.5,
  onClose: callback,
});

const TITLE = {
  info: 'Informação',
  error: 'Erro',
  success: 'Sucesso',
  warn: 'Atenção',
};

const alertMessage = (type, message, callback) => notification[type](body(TITLE[type], message, callback));

const dynamicResponseError = (err, INITIAL_MSG) => {
  let msg = INITIAL_MSG;
  if (err.response && err.response.data && err.response.data.body && err.response.data.body.error) {
    msg = err.response.data.body.error;
  } else if (err.response && err.response.data && err.response.data.message) {
    msg = err.response.data.message;
  } else if (err.response && err.response.data && err.response.data.body && typeof err.response.data.body === 'string') {
    msg = err.response.data.body;
  }
  MessageDealer.error(msg);
};

const MessageDealer = {

  info: (message, callback) => {
    alertMessage('info', message, callback);
  },

  error: (message, callback) => {
    alertMessage('error', message, callback);
  },

  success: (message, callback) => {
    alertMessage('success', message, callback);
  },

  warn: (message, callback) => {
    alertMessage('warn', message, callback);
  },

  dealer: (type, statusCode, expected, message, fallback) => {
    if (+statusCode !== +expected) {
      message = fallback;
    }
    notification[type](body(TITLE[type], message));
  },

  explicitError: (title, content) => {
    Modal.error({
      title,
      content,
    });
  },

  dynamicResponseError,
};

export default MessageDealer;
