const Roles = {
  'GERENTE_RESPONSAVEL': 'Gerente Responsável',
  'TECNICO_RESPONSAVEL': 'Técnico Responsável',
  'CONSULTOR_YANDEH': 'Consultor Yandeh',
  'REPRESENTANTE_LEGAL': 'Representante Legal',
};

const Headlights = {
  'LOJA_COLETANDO': {
    'text': 'Coletando',
    'color': 'rgba(75, 192, 192)',
    'constant': 'LOJA_COLETANDO',
  },
  'LOJA_SEM_COLETA': {
    'text': 'Sem Coleta',
    'color': 'rgb(255, 99, 132)',
    'constant': 'LOJA_SEM_COLETA',
  },
};

const Stages = {
  'INTEGRACAO_INICIADA': 'Integração Iniciada',
  'ANALISE_PERDIDA': 'Análise Perdida',
  'NDA_ANALISE': 'NDA Análise',
  'CONTRATO_ANALISE': 'Contrato Análise',
  'CONTRATO_ASSINADO': 'Contrato Assinado',
  'AGUARDANDO_AGENDAMENTO': 'Aguardando Agendamento',
  'NEGOCIACAO': 'Negociação',
  'GANHO_FECHADO': 'Ganho Fechado',
  'NEGOCIO_PERDIDO': 'Negócio Perdido',
  'PROSPECT': 'Prospect',
  'HOMOLOGADO_COM_CONTRATO': 'Homologado com Contrato',
  'PARALISADO_COMERCIAL': 'Paralisado Comercial',
  'NDA_ASSINADO': 'NDA Assinado',
  'SUSPEITO': 'Suspeito',
  'LEAD': 'LEAD',
  'HOMOLOGADO_SEM_CONTRATO': 'Homologado sem Contrato',
  'ATIVACAO': 'Ativação',
};

const Origins = {
  'APP_VENDEDOR': 'APP Vendedor',
  'BITRIX_1': 'Bitrix 1.0',
  'BITRIX_2': 'Bitrix 2.0',
  'CADASTRO_INSTITUCIONAL': 'Cadastro Institucional',
  'CADASTRO_WEB': 'Cadastro Web',
  'CALCULADORA_ALPE': 'Calculadora Alpe',
  'CARGA_BACKOFFICE': 'Carga Backoffice',
  'CARGA_BITRIX': 'Carga Bitrix',
  'MOVIDESK': 'Movidesk',
  'PORTAL_ERP': 'Portal ERP',
  'SITE_INTEGRA_FARMA': 'Site Integrafarma',
  'BITRIX': 'Bitrix',
};

export {Headlights, Roles, Origins, Stages};
