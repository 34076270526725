import {Headlights} from '../../../mocks';

class BarHeadlightsController {
  defineOptions = function(handleRedirect) {
    return {
      chart: {
        type: 'bar',
        dropShadow: {
          enabled: true,
          blur: 1,
          opacity: 0.20,
        },
        events: {
          dataPointSelection: function(event, chartContext, config) {
            // x é o label, y é o valor e key é a constante que representa o label
            handleRedirect(chartContext.opts.series[0].data[config.dataPointIndex].key);
          },
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'center',
          },
        },
      },
      grid: {
        xaxis: {lines: {show: false}},
        yaxis: {lines: {show: false}},
      },
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: true,
        },
        formatter: (val) => ' '+val,
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: false,
        },
        y: {
          title: {formatter: () => ''},
        },
      },
    };
  }

  incrementHeadlight = (dataset, data, options, headlight) => {
    const value = dataset[headlight.constant];
    if (value) {
      data.push({x: headlight.text, y: value, key: headlight.constant});
      options.colors.push(headlight.color);
    }
  }

  construct = (dataset, handleRedirect) => {
    const data = [];
    const options = {
      ...this.defineOptions(handleRedirect),
      ...{xaxis: {type: 'category', labels: {formatter: (val) => parseInt(val)}}},
      ...{colors: []},
    };

    this.incrementHeadlight(dataset, data, options, Headlights.LOJA_SEM_COLETA);
    this.incrementHeadlight(dataset, data, options, Headlights.LOJA_COLETANDO);

    return {
      series: [{data}],
      options,
    };
  }
}

export default BarHeadlightsController;
