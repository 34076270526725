import {Address, Partner} from '.';
import {onlyNumbers} from '../utils/Formatters';

class Company {
  constructor({
    cnpj,
    email,
    phone,
    type,
    segment,
    profile,
    trademark,
    name,
    pdv,
    inactivation,
    openingDate,
    codeMainEconomicActivity,
    descriptionMainEconomicActivity,
    address,
    partner,
    origin,
    billingAmount,
  }) {
    this.cnpj = cnpj;
    this.email = email;
    this.phone = phone;
    this.type = type;
    this.segment = segment;
    this.profile = profile;
    this.trademark = trademark;
    this.name = name;
    this.pdv = pdv;
    this.inactivation = inactivation;
    this.openingDate = openingDate;
    this.codeMainEconomicActivity = codeMainEconomicActivity;
    this.descriptionMainEconomicActivity = descriptionMainEconomicActivity;
    this.address = address;
    this.partner = partner;
    this.origin = origin;
    this.billingAmount = billingAmount;
  }

  static fromSerpro(data) {
    return new Company({
      cnpj: data.ni,
      name: data.nome_empresarial,
      trademark: data.nome_fantasia || data.nome_empresarial,
      phone: data.telefones && data.telefones.length && onlyNumbers(`55${data.telefones[0].ddd}${data.telefones[0].numero}`),
      address: Address.fromSerpro(data.endereco),
      email: data.correio_eletronico && data.correio_eletronico.toLowerCase(),
    });
  }

  static fromApi(data) {
    return new Company({
      cnpj: data.cnpj,
      email: data.email,
      phone: data.phone,
      type: data.type,
      origin: data.origin,
      segment: data.segment,
      profile: data.perfil,
      trademark: data.trademark,
      name: data.name,
      pdv: data.pdv_qty,
      billingAmount: data.billing_amount,
      inactivation: !!data.inactivation,
      openingDate: data.opening_date,
      codeMainEconomicActivity: data.code_main_economic_activity,
      descriptionMainEconomicActivity: data.description_main_economic_activity,
      partner: new Partner(data.erp),
      address: Address.fromApi(data),
    });
  }
}

export default Company;
