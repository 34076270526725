/* eslint-disable camelcase */
import api from './Api';

const BASE_ENDPOINT = '/portal-erp/company';

export default {
  getFromSerproByCnpj(cnpj) {
    return api.get(`/barramento/empresa/${cnpj}`);
  },
  submit(payload) {
    return api.post(`${BASE_ENDPOINT}`, payload);
  },
  getSegmentos() {
    return api.get(`/segmentos`);
  },
  getPerfis(segmento) {
    return api.get(`/perfis/segmentos/${segmento}`);
  },
  getEmpresas(cnpj, cnpj_erp) {
    return api.get(`${BASE_ENDPOINT}`, {cnpj, cnpj_erp});
  },
  inactivate(cnpj, justificativa) {
    return api.put(`${BASE_ENDPOINT}/inactivate/${cnpj}`, {justificativa});
  },
};
