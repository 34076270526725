import React from 'react';
import {Card, Spin} from 'antd';
import PropTypes from 'prop-types';
import DS from '../../utils/DefaultStyles';

function Container({children, loading = false, asCard = false, withTable = false, action}) {
  return (
    <Spin spinning={loading} tip="Aguarde.." style={{top: '-18px'}}>
      <Card bordered={false} bodyStyle={withTable ? {padding: '24px 0'} : {}} style={asCard ? DS.card : {}}>
        {children}
      </Card>
    </Spin>
  );
}

Container.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  withstyle: PropTypes.bool,
  action: PropTypes.func,
  asCard: PropTypes.bool,
  withTable: PropTypes.bool,
};

export default Container;
