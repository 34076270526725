import Context from '../../utils/Context';
import {dashboardService} from '../../services';

class DashboardController {
  loadDashboards(instance) {
    this.loadBarHeadlights(instance);
  }

  async loadBarHeadlights(instance) {
    instance.setState({loadingBarHeadlights: true});
    const cnpjErp = Context.idPartner();
    const data = await dashboardService.getDashboardStatusCompanies(cnpjErp)
        .then((res) => res.data.result)
        .catch((err) => null)
        .finally(() => instance.setState({loadingBarHeadlights: false}));

    instance.setState({dataBarHeadlights: data});
  }
}

export default DashboardController;
