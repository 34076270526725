/* eslint-disable camelcase */
import api from './Api';

const BASE_ENDPOINT = '/portal-erp/contact';

export default {
  get(email) {
    return api.get(`${BASE_ENDPOINT}/${email}`);
  },
  getCargos() {
    return api.get(`/cargos`);
  },
};
