/* eslint-disable camelcase */
import api from './Api';

const BASE_ENDPOINT = '/portal-erp/dashboard';

export default {
  getDashboardStatusCompanies(cnpj_erp) {
    return api.get(`${BASE_ENDPOINT}/status-companies`, {cnpj_erp});
  },
};
