import React from 'react';

import DS from '../../utils/DefaultStyles';

function TitleFormItem(props) {
  return (
    <span
      style={{color: '#707478', fontFamily: DS.font.ralewaySemiBold, marginBottom: 0}}
      level={4} {...props}></span>
  );
}

export default TitleFormItem;
