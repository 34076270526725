import React from 'react';
import {Form} from 'antd';

import DS from '../../utils/DefaultStyles';

function TitleColumn(props) {
  return (
    <Form.Item>
      <span style={{color: '#FF6A14', fontFamily: DS.font.ralewaySemiBold, fontSize: '17px'}} {...props}></span>
    </Form.Item>
  );
}

export default TitleColumn;
