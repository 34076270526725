import React from 'react';
import {Button, Modal, Table, Input} from 'antd';
import Context from '../../utils/Context';
import {erpLinkService} from '../../services';
import Text from '../../components/Text';

class ERPLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      erpList: [],
      filteredList: [],
    };
  }

  filterList = (event) => {
    const value = String(event.target.value || '').toUpperCase();
    const {erpList} = this.state;
    if (!value || value.length < 3) {
      this.setState({filteredList: erpList});
      return;
    }
    const filteredList = erpList.filter((el) => el.name.includes(value));
    this.setState({filteredList});
  }

  collumns = () => {
    return [
      {
        title: 'Parceiro',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: (
          <Input placeholder="Procurar parceiro" onChange={this.filterList} />
        ),
        dataIndex: 'link',
        key: 'link',
        render: (value) => (<Text text={value} copyable>{value}</Text>),
      },
    ];
  }

  componentDidMount() {
    erpLinkService.getERPLinks()
        .then((res) => res.data.links)
        .then((erpList) => this.setState({erpList, filteredList: erpList.slice(0)}))
        .catch((err) => {
          console.log(err);
        });
  }

  openCloseModal = () => {
    const {modalVisible} = this.state;
    this.setState({modalVisible: !modalVisible});
  }

  masterModalContent = () => (
    <Table dataSource={this.state.filteredList} columns={this.collumns()} />
  )

  userModalContent = () => (
    <Table dataSource={this.state.erpList} showHeader={false} pagination={false} columns={this.collumns()} />
  )

  erpLinkModal = () => (
    <Modal title="Link ERP"
      visible={this.state.modalVisible}
      onCancel={this.openCloseModal}
      width={1024}
      footer={null}
    >
      {Context.isMaster() ? this.masterModalContent() : this.userModalContent()}
    </Modal>
  )

  render() {
    return <>
      <Button className="erp-link bt-header" type="primary" shape="circle" icon="export" onClick={this.openCloseModal} />
      {this.erpLinkModal()}
    </>;
  }
}

export default ERPLink;
