import React from 'react';
import {Button} from 'antd';
import {useAuth0} from '@auth0/auth0-react';


const Logout = () => {
  const {logout} = useAuth0();

  return (
    <Button
      className="logout-bt bt-header"
      type="primary"
      shape="circle"
      icon="logout"
      onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}
    />
  );
};


export default Logout;
