import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Form, Col, Row} from 'antd';

import {Deal} from '../../entities';
import Text from '../../components/Text';
import {Roles, Headlights} from '../../mocks';
import TitleColumn from '../../components/TitleColumn';
import TitleFormItem from '../../components/TitleFormItem';
import TitleModal from '../../components/TitleModal/TitleModal';
import {cepMask, cnpjMask, phoneMask} from '../../utils/Formatters';

const titleFormItem = (content) => <TitleFormItem>{content}</TitleFormItem>;


const linkTermItem = (textLink) => {
  return textLink ? <a href={textLink} target={'_blank'} rel='alternate noopener noreferrer'>Visualizar termo</a> : '--';
};

const DealDetails = ({visible, close, entity}) => {
  const deal = entity;
  return (
    <Modal
      id='detalheNegocio'
      title={<TitleModal>Detalhes do Negócio</TitleModal>}
      visible={visible}
      onCancel={close}
      destroyOnClose={true}
      width={'75vw'}
      footer={false}
    >
      <Row gutter={32}>
        <Col span={6}>
          <Form layout="vertical">
            <TitleColumn>Empresa</TitleColumn>
            <Form.Item label={titleFormItem('CNPJ')}>
              <Text text={deal.company.cnpj} copyable>{cnpjMask(deal.company.cnpj)}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Razão Social')}>
              <Text>{deal.company.name}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Nome Fantasia')}>
              <Text>{deal.company.trademark}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('E-mail')}>
              <Text copyable>{deal.company.email}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Telefone')}>
              <Text copyable text={deal.company.phone}>{phoneMask(deal.company.phone)}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Valor de Faturamento')}>
              <Text>{deal.company.billingAmount ? 'R$ ' + deal.company.billingAmount : '--'}</Text>
            </Form.Item>
          </Form>
        </Col>
        <Col span={6}>
          <Form layout="vertical">
            <TitleColumn>Endereço</TitleColumn>
            <Form.Item label={titleFormItem('CEP')}>
              <Text text={deal.company.address.cep}>{cepMask(deal.company.address.cep)}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Logradouro')}>
              <Text>{deal.company.address.street}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Complemento')}>
              <Text>{deal.company.address.complement}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Bairro')}>
              <Text>{deal.company.address.neighborhood}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Cidade')}>
              <Text>{deal.company.address.city}</Text>
            </Form.Item>
          </Form>
        </Col>
        <Col span={6}>
          <Form layout="vertical">
            <TitleColumn>Contato</TitleColumn>
            <Form.Item label={titleFormItem('E-mail')}>
              <Text copyable>{deal.contact.email}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Nome')}>
              <Text>{`${deal.contact.name}`}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Cargo')}>
              <Text>{deal.contact.cargo && (Roles[deal.contact.cargo] || deal.contact.cargo)}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Celular')}>
              <Text copyable text={deal.contact.phone}>{phoneMask(deal.contact.phone)}</Text>
            </Form.Item>
          </Form>
        </Col>
        <Col span={6}>
          <Form layout="vertical">
            <TitleColumn>Negócio</TitleColumn>
            <Form.Item label={titleFormItem('Segmento')}>
              <Text>{deal.company.segment}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Valor Cobrado')}>
              <Text>{deal.amountCharged ? 'R$ ' + deal.amountCharged : '--'}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Valor Repasse')}>
              <Text>{deal.amountTransfer ? 'R$ ' + deal.amountTransfer : '--'}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Farol')}>
              <Text>{Headlights[deal.headlight] && Headlights[deal.headlight].text}</Text>
            </Form.Item>
            <Form.Item label={titleFormItem('Link do Termo de Aceite')}>
              <Text>{linkTermItem(deal.termLink)}</Text>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

DealDetails.propTypes = {
  visible: PropTypes.bool,
  close: PropTypes.func,
  entity: PropTypes.instanceOf(Deal),
};

export default DealDetails;
