import get from 'lodash/get';
import _debounce from 'lodash.debounce';


const _clearedData = (value) => value.toLowerCase().trim().replace(/_/g, ' ').replace(/[^\w\s]/gi, '');

const _filterData = (originalData, schema, value) => {
  return originalData.filter((item) => {
    const content = schema.map((key) => get(item, key)).map((v = '') => {
      if (typeof (v) !== 'string') {
        return false;
      }
      return _clearedData(v).indexOf(_clearedData(value)) > -1;
    }).filter((f) => !!f);
    return content.length;
  });
};

export default {
  searchData(e) {
    const {value} = e.target;
    const {originalData, columns} = this.state;
    const schema = columns && columns.map((column) => column.key).filter((f) => !!f);
    const debounced = _debounce((v) => this.setState({datasource: _filterData(originalData, schema, v)}), 200);
    debounced(value);
  },
  filterAutocomplete: (inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1,
};
