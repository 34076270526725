import React from 'react';
import {Layout, Menu} from 'antd';
import Context from '../../utils/Context';
import './styles.css';
import defaultLogo from '../../assets/images/logo-yandeh.png';
import Guide from '../Guide';
import ERPLink from '../ERPLink';
import DocYI from '../DocYandehIntegration/DocYandehIntegration';
import DocColetor from '../DocColetorYandeh/DocColetorYandeh';
import Logout from '../Logout/Logout';
import Profile from '../Profile';
const {Header} = Layout;


function renderLogoAtual() {
  const logo = window.localStorage.logo;
  if (logo) {
    return (<img alt="Logo Atual" src={logo}></img>);
  } else {
    return (<img alt="Logo Atual" src={defaultLogo} id="logoDefault"></img>);
  }
}

const CustomHeader = (props) => {
  return (
    <div>
      <Header className="header">
        <div className="logo" >
          {renderLogoAtual()}
        </div>
        <Menu
          style={{lineHeight: '64px', float: 'right', borderRight: 0}}
          mode="horizontal"
          selectable=""
        >
          <Guide />
          <ERPLink />
          {Context.isMaster() || Context.isAdmin() ? <DocYI /> : null}
          {Context.isMaster() || Context.isAdmin() ? <DocColetor /> : null}
          <Profile />
          <Logout />
        </Menu>
      </Header>
    </div >
  );
};

export default CustomHeader;
